function IconNegoBed(props) {
	return (
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M19.1453 11.2555V9.87842C19.1408 9.50388 18.9878 9.14645 18.72 8.88458C18.4522 8.62271 18.0914 8.47781 17.7168 8.48167H13.8322C13.6734 8.48302 13.5165 8.51568 13.3704 8.57776C13.2243 8.63985 13.0918 8.73015 12.9807 8.84349C12.8695 8.95683 12.7818 9.09099 12.7225 9.23828C12.6633 9.38557 12.6337 9.54309 12.6354 9.70184C12.634 9.76092 12.6365 9.82003 12.643 9.87876V11.2559L19.1453 11.2555ZM11.3542 9.87842C11.3497 9.50388 11.1967 9.14645 10.9289 8.88458C10.6611 8.62271 10.3003 8.47781 9.92574 8.48167H6.28074C5.90614 8.47781 5.54531 8.62276 5.27748 8.8847C5.00966 9.14664 4.85673 9.50416 4.85227 9.87876V11.2559H11.3539L11.3542 9.87842ZM3.56347 11.2555V9.87842C3.57192 9.16603 3.86289 8.48614 4.37242 7.98818C4.88195 7.49023 5.56834 7.21496 6.28074 7.22287H9.92643C10.3057 7.2216 10.681 7.29919 11.0287 7.4507C11.3764 7.60221 11.6888 7.82434 11.946 8.10299C12.1734 7.82266 12.4617 7.59774 12.7889 7.44525C13.116 7.29277 13.4736 7.21672 13.8346 7.22287H17.7189C18.4312 7.21487 19.1176 7.49014 19.627 7.98814C20.1363 8.48614 20.4271 9.16609 20.4352 9.87842V11.2555H21.5815V6.24722C21.5815 4.31246 21.2587 4 19.2836 4H4.7157C2.73783 4 2.41779 4.31556 2.41779 6.24722V11.2555H3.56347ZM1 12.515V17.4467H2.64644V19.057H3.93938V17.4467H20.0606V19.057H21.3532V17.4467H23V12.5167L1 12.515Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default IconNegoBed;
